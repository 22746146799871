@import "@/scss/_variables.scss";























































.pr-16 {
  padding-right: 16px !important;
}
