@import "@/scss/_variables.scss";







































.payload {
  .grid {
    grid-column-gap: 32px;

    @media (max-width: $width-tablet) {
      grid-template-columns: 1fr;
      grid-row-gap: 24px;
    }
  }

  .payload-image {
    width: 100%;
    height: auto;
    margin-top: 24px;
  }

  .spacing {
    margin-top: 24px;
  }
}
