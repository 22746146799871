@import "@/scss/_variables.scss";



















































.input-single-load {
  display: grid;
  grid-template-columns: 4fr 1fr 1fr 1fr;
  width: 100%;

  font-size: 0.762rem;

  @media (max-width: $width-tablet) {
    font-size: 1rem;
  }

  @media (max-width: $width-tablet) {
    grid-template-columns: 1fr;
  }

  thead, tbody, tr {
    display: contents;
  }

  tr {
    height: auto;

    td {
      width: 100%;
      height: 100%;
      padding: 8px 0 8px 0;
      border-bottom: 1px solid #69777F;
    }
  }

  button {
    display: flex;
    align-items: center;
    width: auto;
    height: 100%;

    background-color: transparent;
    border: none;
    font-family: futura-pt-condensed, sans-serif;
    font-weight: bold;
    font-size: 0.762rem;
    cursor: pointer;

    img {
      margin-right: 8px;
    }
  }

  thead {
    font-family: futura-pt-condensed, sans-serif;
    color: #293136;
    font-weight: bold;

    .optional {
      color: #69777F;
    }
  }

  tbody {
    font-family: futura-pt-book, sans-serif;

    button {
      color: #293136;
    }

    input {
      height: 100%;
      padding: 0 0 4px 0;

      border: none;
      border-bottom: 1px solid #CAD3D9;
    }

    @media (max-width: $width-tablet) {
      input {
        width: 100%;

        @media (max-width: $width-tablet) {
          font-size: 1rem;
        }
      }

      select {
        width: 100%;

        @media (max-width: $width-tablet) {
          font-size: 1rem;
        }
      }
    }
  }

  tfoot {
    td {
      padding: 8px 0 0 0;
      border-bottom: 0;

      button {
        color: #E3000B;

        @media (max-width: $width-tablet) {
          font-size: 1rem;
        }
      }
    }
  }
}
